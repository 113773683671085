<script>
  import { DialogOverlay, DialogContent } from "svelte-accessible-dialog";
  import { Tabs, Tab, TabList, TabPanel } from "svelte-tabs";
  import StatsTable from "./StatsTable.svelte";
  import StatsChart from "./StatsChart.svelte";
  import {
    allPixelCategoriesStore,
    cropsListStore,
    cropsSelectionStore,
    datasetParam,
    exportFeaturesStore,
    exportYearStore,
  } from "../../stores";
  import { computeImageryStatistics } from "../../utils";
  import appConfig from "../../appConfig";

  export let isOpen;
  export let close;

  // use an AbortController and AbortSignal to cancel the long-running GP request
  // if the user closes the modal dialog before it finishes fetching histograms
  let abortController;

  function closeDialog() {
    // run our AbortController logic before continuing with close logic in <AnalysisMenu> parent component
    if (abortController && abortController.abort) {
      abortController.abort();
      abortController = null;
    }

    close();
  }

  // variable tied to a promise to help with conditional UI rendering depending on promise pending, fulfilled, or rejected state
  let computeImageryStatisticsPromise;
  let landTypes = {
    ag: 'Agriculture',
    nonAg: 'Non-Agriculture',
    all: 'All'
  }
  let selected = landTypes.ag;
  // when the dialog is opened and if required stores data are available,
  // asynchronously fetch and process pixel category statistics
  $: if (isOpen) {
    if (
      allPixelCategoriesStore &&
      cropsListStore &&
      cropsSelectionStore &&
      exportFeaturesStore &&
      exportYearStore
    ) {
      computeImageryStatisticsPromise = processImageryStatistics();
    }
  }

  // wrapper around GP async calculate stats/histograms util
  async function processImageryStatistics() {
    try {
      // establish a new AbortController and pass the AbortSignal to the geoprocessor async task
      abortController = new AbortController();

      // typically there will only be 1 feature and 1 cache key, but it is possible to have multiple AOIs at once via the upload shapefile tool
      // here we create a gpCacheKey that is a joined string of the unique individual cache keys found in each feature object
      const gpCacheKey = [
        ...new Set(
          $exportFeaturesStore.map((feature) => feature.attributes._gpCacheKey)
        ),
      ].join("_");

      // go get histograms
      const { resultData } = await computeImageryStatistics(
        $datasetParam,
        $exportFeaturesStore,
        $exportYearStore,
        gpCacheKey,
        abortController.signal
      );

      // var type = "CDLS_WM"
      // format the response data for <StatsTable> and <StatsChart>
      const histogramCounts = resultData.value.histograms[0].counts;
      let histogramPixelCategories = [];
      let output = {}
      // the array of histogram category counts is in the same 0-based index order as the imagery layer's service attribute table
      // recall that in <EsriMapView> we reformatted this info and stored it in $allPixelCategoriesStore
      histogramCounts.forEach((categoryCount, idx) => {
        console.log("In function call")
        if (categoryCount > 0) {
          if($allPixelCategoriesStore[idx]!== undefined) {
            const pixelAttributes = $allPixelCategoriesStore[idx];
            var categoryText = pixelAttributes.serviceAttributes.ClassName;
            if($datasetParam=="CDLS_WM") {
              categoryText = pixelAttributes.displayText
            }
            let acerage = categoryCount * 0.222395; //formula provided by ARS to calculate acres from 30 sq m pixels (and then we round to nearest integer)
            let acerageRoundedToNearest10th = parseFloat(acerage.toFixed(1));
            histogramPixelCategories.push({
              // info provided by and derived from `computeImageryStatistics`
              categoryCount,
              area: acerageRoundedToNearest10th,

              // info provided by raster attribute table info stored in $allPixelCategoriesStore
              value: pixelAttributes.value,
              displayText: categoryText,
              colorSwatch: pixelAttributes.colorSwatch,
              ag: !appConfig.nonAgCategories.includes(pixelAttributes.displayText)
            });
          }
        }
      });

      // if there are currently selected crops, then use them as the allowed list of categories that can show up in the table and chart
      // otherwise use the entire list of possible crop types (the same list that populated the select crops combobox UI/UX)
      let pixelCategoriesInfo = $cropsSelectionStore.length
        ? $cropsSelectionStore
        : $cropsListStore;      
      console.log("pixelCategoriesInfo:", pixelCategoriesInfo)
      if($datasetParam=="CDLS_WM") {
        histogramPixelCategories = histogramPixelCategories
        .filter(
          (i) => pixelCategoriesInfo.findIndex((c) => c.value == i.value) > -1
        ) // keep only those that also have a raster attribute table value match (just to be safe)
        // .filter((i) => i.area > 0); // keep only those that have a calculated acres area larger than 0 (note the rounding that already occurred above; acreages less than 0.5 will be filtered out)
      }
      
      // sort descending by category count
      histogramPixelCategories.sort(
        (a, b) => b.categoryCount - a.categoryCount
      );

      // sort by ag vs non-ag
      histogramPixelCategories.sort(
        (a, b) => b.ag - a.ag
      )

      var allCategoriesAgFilter = histogramPixelCategories
      var allCategoriesNonAgFilter = histogramPixelCategories

      var ag = allCategoriesAgFilter
        .filter(
          (i) =>i.ag==true)
      var nonAg = allCategoriesNonAgFilter
        .filter(
          (i) =>i.ag==false
      )
      output.all = histogramPixelCategories
      output.ag = ag
      output.nonag = nonAg
      output.histogramPixelCategories = histogramPixelCategories
      return output;
    } catch (error) {
      throw new Error(error);
    }
  }
</script>

<DialogOverlay {isOpen} onDismiss={closeDialog} class="overlay">
  <DialogContent
    aria-label="Area of Interest &mdash; Statistics"
    class="content dialog-box-content stats"
  >
    <div class="dialog-title-wrapper">
      <h2>Area of Interest &mdash; Statistics</h2>

      <!-- custom close button, wrapped up in a div with the h2 title text -->
      <button
        class="usa-button usa-button--unstyled custom-dialog-button"
        title="Close"
        on:click={closeDialog}
      >
        <span class="esri-icon-close" style="vertical-align: top" />
      </button>
    </div>

    <!-- conditional UI depending on promise pending, fulfilled, or rejected state -->
    {#await computeImageryStatisticsPromise}
      <div style="text-align: center">
        <!-- position relative helps the custom-updating indicator work properly here -->
        <p class="custom-updating" style="position: relative; padding: 12px">
          Calculating statistics...
        </p>
      </div>
    {:then output}
      <Tabs>
        <TabList>
          <Tab>Table</Tab>
          <Tab>Chart</Tab>
        </TabList>
        <TabPanel>
          <select class="land-type" bind:value={selected}>
              <option value={landTypes.ag}>
                {landTypes.ag}
              </option>
              <option value={landTypes.nonAg}>
                {landTypes.nonAg}
              </option>
              <option value={landTypes.all}>
                {landTypes.all}
              </option>
          </select>
          {#if selected == landTypes.ag}
            <StatsTable values={output.ag} />
          {:else if selected == landTypes.nonAg}
            <StatsTable values={output.nonag} />
          {:else if selected == landTypes.all}
            <StatsTable values={output.all} />
          {/if}

        </TabPanel>

        <TabPanel>
          <StatsChart values={output.histogramPixelCategories} />
        </TabPanel>
      </Tabs>

      <p class="disclaimer">
        Note: Pixel and acreage counts are not official estimates.
      </p>
    {:catch error}
      <div class="error-dialog-container" style="text-align: center">
        <div class="error-title-container">
          <p class="error-title">
            <span
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.7146 19.8309V17.09C13.7181 17.0305 13.7098 16.9709 13.6901 16.9147C13.6704 16.8584 13.6398 16.8067 13.6 16.7624C13.5601 16.718 13.5119 16.6821 13.458 16.6566C13.4042 16.6311 13.3458 16.6165 13.2863 16.6137H10.7145C10.6549 16.6165 10.5966 16.6311 10.5427 16.6566C10.4889 16.6821 10.4406 16.718 10.4008 16.7624C10.361 16.8067 10.3303 16.8584 10.3106 16.9147C10.291 16.9709 10.2827 17.0305 10.2862 17.09V19.8309C10.2827 19.8904 10.291 19.95 10.3106 20.0062C10.3303 20.0625 10.361 20.1143 10.4008 20.1586C10.4406 20.2029 10.4889 20.2389 10.5427 20.2644C10.5966 20.2899 10.6549 20.3045 10.7145 20.3072H13.2863C13.3458 20.3045 13.4042 20.2899 13.458 20.2644C13.5119 20.2389 13.5601 20.2029 13.6 20.1586C13.6398 20.1143 13.6704 20.0625 13.6901 20.0062C13.7098 19.95 13.7181 19.8904 13.7146 19.8309ZM13.6875 14.4362L13.9283 7.81497C13.9275 7.76226 13.915 7.71038 13.8918 7.66305C13.8686 7.61572 13.8352 7.5741 13.7941 7.54118C13.7083 7.45268 13.5947 7.39651 13.4723 7.38216H10.5262C10.4038 7.39651 10.2902 7.45268 10.2044 7.54118C10.1628 7.57943 10.1295 7.62577 10.1064 7.67736C10.0833 7.72895 10.071 7.78471 10.0702 7.84122L10.2952 14.4332C10.2952 14.6207 10.4962 14.7647 10.7505 14.7647H13.2255C13.3296 14.7772 13.4346 14.7505 13.52 14.6897C13.6054 14.629 13.6652 14.5386 13.6875 14.4362ZM13.5 0.962749L23.7873 21.2734C23.9316 21.5555 24.0045 21.8687 23.9998 22.1855C23.9951 22.5023 23.9129 22.8132 23.7603 23.0909C23.6191 23.3608 23.4078 23.5877 23.1486 23.7477C22.8894 23.9076 22.5919 23.9948 22.2873 24H1.7127C1.40814 23.9948 1.11061 23.9076 0.851398 23.7477C0.592182 23.5877 0.380861 23.3608 0.239657 23.0909C0.0871291 22.8132 0.00492088 22.5023 0.000214163 22.1855C-0.00449255 21.8687 0.0684454 21.5555 0.212656 21.2734L10.5 0.962749C10.6316 0.675484 10.8429 0.432054 11.1088 0.261389C11.3747 0.0907236 11.684 0 12 0C12.316 0 12.6253 0.0907236 12.8912 0.261389C13.1571 0.432054 13.3684 0.675484 13.5 0.962749Z"
                  fill="#171717"
                />
              </svg>
            </span>
            The requested area of interest exceeds the maximum size currently supported
          </p>
          <p style="padding: 12px">Please try again with a smaller area.</p>
        </div>
      </div>
    {/await}
  </DialogContent>
</DialogOverlay>

<style>
  /* https://github.com/reecelucas/svelte-accessible-dialog#styling */
  :global([data-svelte-dialog-overlay].overlay) {
    /* keep it above the <HeaderToolbar>, otherwise the dialog doesn't block those tools */
    z-index: 1100;
  }

  /* :global([data-svelte-dialog-content].content) {  } */

  /* CSS for custom design */
  :global(.content.dialog-box-content.stats) {
    padding: 0rem !important;
    margin: 10vh auto !important;
    width: 60vw !important;
    height: 80%;
    display: flex;
    flex-direction: column;
  }

  /* to aid in aligning the title text and our custom close button */
  .dialog-title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #005ea2;
  }

  .dialog-title-wrapper .custom-dialog-button {
    background-color: #005ea2;
    color: #fff;
    margin-right: 0px !important;
    padding: 18px 20px !important;
    z-index: 1;
  }

  .dialog-title-wrapper .custom-dialog-button:hover {
    background-color: #082e65 !important;
  }

  .dialog-title-wrapper h2 {
    color: #ffffff;
    margin: 16px;
    flex: 1;
    text-align: center;
    position: relative;
    left: 32px;
    font-size: 20px;
    line-height: 100%;
  }

  :global(.svelte-tabs) {
    /* 100% of the dialog height, minus the dialog header, minus the disclaimer footer */
    height: calc(100% - 52px - 38px);
  }

  :global(.svelte-tabs__tab-list) {
    display: flex;
    border-bottom: none !important;
  }

  :global(.svelte-tabs__tab-panel) {
    padding: 0 20px;
  }

  :global(.svelte-tabs__tab) {
    flex: 1;
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: none !important;
  }

  :global(.svelte-tabs__tab:hover) {
    background-color: #e1eff9;
  }

  :global(.svelte-tabs__selected) {
    color: #005ea2 !important;
    background-color: #ffffff !important;
    font-weight: 700;
    z-index: 2;
  }

  :global(.svelte-tabs__tab-panel .usa-button) {
    margin-bottom: 20px;
    width: 180px;
  }

  .error-dialog-container {
    flex: 1 1 auto;
    padding: 24px 40px;
    display: flex;
    flex-direction: column;
  }

  .land-type {
    height: 48px;
  }

  .error-title-container {
    background-color: #f4e3db;
    padding: 16px;
  }

  .error-title svg {
    position: relative;
    top: 4px;
    margin-right: 12px;
  }

  .error-title {
    font-weight: 600;
    color: #171717;
  }

  .disclaimer {
    margin: 8px 20px 20px 20px;
    font-style: italic;
    color: #535353;
  }
</style>
