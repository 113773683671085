<script>
  import Search from "./headerTools/Search.svelte";
  import SelectArea from "./headerTools/SelectArea.svelte";
  import DefineArea from "./headerTools/DefineArea.svelte";
  import SelectCrops from "./headerTools/SelectCrops.svelte";
  import AnalysisMenu from "./headerTools/AnalysisMenu.svelte";
  import ExportMenu from "./headerTools/ExportMenu.svelte";
  import SelectLayer from "./headerTools/SelectLayer.svelte";
  import { appMode } from "./stores";

  // these props are also bound to the parent <App> which helps "send" them over to the alert components
  // which show different alerts UI depending on the status of the async export GP task
  export let exportJobType = null; // null, 'exportAOI', 'print'
  export let exportJobStatus = null; // null, 'processing', 'success', 'failure'
  export let exportJobInfo = null;

  let expandedSelectAreaControl;
  let expandedDefineAreaControl;
  let expandedSelectCropsControl;
  let expandedAnalysisMenuControl;
  let expandedExportMenuControl;
  let expandedSelectLayerControl;
  collapseAllCustomDropdowns();
  function collapseAllCustomDropdowns() {
    expandedSelectAreaControl = false;
    expandedDefineAreaControl = false;
    expandedSelectCropsControl = false;
    expandedAnalysisMenuControl = false;
    expandedExportMenuControl = false;
    expandedSelectLayerControl = false;
  }
</script>

<!--
  wire up a body-wide click handler, which will help to auto-close the nav dropdown menus
  if the click occurs in any element that does NOT have a particular <li class="usa-nav__primary-item collapsible"> as an ancestor
-->
<svelte:body
  on:click={(e) => {
    // first check if the element on which event occurred (e.target) is in the body (e.currentTarget)
    // because in some situations it may have already been removed from the body before this click handler runs
    // for example, when clicking on a dismissable chip/tag in the crop selection dropdown tool
    if (e.currentTarget.contains(e.target)) {
      // if it still exists in the body, then check if the dropdown's main <li> element is NOT an ancestor
      if (!e.target.closest("li.usa-nav__primary-item.collapsible")) {
        collapseAllCustomDropdowns();
      }
    }
  }}
/>

<header class="usa-header usa-header--basic toolbar">
  <div class="usa-nav-container">
    <div class="usa-navbar">
      <button class="usa-menu-btn">Menu</button>
    </div>

    <nav aria-label="Primary navigation" class="usa-nav">
      <button class="usa-nav__close">
        <img
          src="./build/uswds/img/usa-icons/close.svg"
          role="img"
          alt="close"
        />
      </button>

      <ul class="usa-nav__primary usa-accordion">
        <!-- JSAPI Search widget inside of a <Search> component -->
        <Search />

        <!-- Select Layer tools -->
        <SelectLayer
          bind:expanded={expandedSelectLayerControl}
          {collapseAllCustomDropdowns}
        />

        <!-- Select Area tools -->
        <SelectArea
          bind:expanded={expandedSelectAreaControl}
          {collapseAllCustomDropdowns}
        />

        <!-- Define Area tools -->
        <DefineArea
          bind:expanded={expandedDefineAreaControl}
          {collapseAllCustomDropdowns}
        />

        <!-- vertical divider -->
        <div
          style="
            align-self: center;
            width: 2px;
            height: 32px;
            background-color: rgba(18, 46, 81, 1);
          "
          class="desktop-view-title"
        />

        {#if $appMode == "CDL"}
          <!-- Select Crops tools -->
          <SelectCrops
            bind:expanded={expandedSelectCropsControl}
            {collapseAllCustomDropdowns}
          />
        {/if}

        <div style="display: flex; margin-left:auto">
          <!-- Analysis tools -->
          <AnalysisMenu
            bind:expanded={expandedAnalysisMenuControl}
            {collapseAllCustomDropdowns}
          />

          <!-- vertical divider -->
          <div
            style="
            align-self: center;
            width: 2px;
            height: 32px;
            background-color: rgba(18, 46, 81, 1);
          "
            class="desktop-view-title"
          />

          <!-- Export tools -->
          <ExportMenu
            bind:expanded={expandedExportMenuControl}
            {collapseAllCustomDropdowns}
            bind:exportJobType
            bind:exportJobStatus
            bind:exportJobInfo
          />
        </div>
      </ul>
    </nav>
  </div>
  <!-- end of .usa-nav-container -->
</header>

<style>
  .toolbar {
    background: #005ea2;
    /* keep it above the Esri MapView DOM, otherwise the MapView's focus CSS can appear on top of the drop down menus */
    z-index: 1000;
  }

  .usa-menu-btn {
    height: 2.8rem;
    cursor: pointer;
  }

  .usa-nav__close {
    cursor: pointer;
  }

  :global(
      .usa-nav__primary
        button.usa-accordion__button.usa-current[aria-expanded="false"]
    ) {
    color: #ffffff;
    /* change which arrow imgs are used */
    background-image: url(../images/expand_more--white.svg),
      linear-gradient(transparent, transparent);
  }

  :global(
      .usa-nav__primary
        button.usa-accordion__button.usa-current[aria-expanded="true"]
    ) {
    background-color: white;
    color: #005ea2;
    /* change which arrow imgs are used */
    background-image: url(../images/expand_less--blue.svg),
      linear-gradient(transparent, transparent);
  }

  :global(.usa-nav__submenu) {
    background-color: #ffffff;
  }

  :global(.submenu-header) {
    margin: 8px 0px 0px 0px;
  }

  :global(button .custom-esri-button-icon) {
    vertical-align: top;
  }

  :global(.submenu-separator) {
    color: #f1f1f1;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  @media (max-width: 63.99em) {
    .usa-nav {
      width: 18rem;
      background: #f0f0f0;
    }

    .usa-navbar {
      border-bottom: none;
    }
  }

  @media (min-width: 64em) {
    .usa-nav-container {
      max-width: 100%;
      margin: 0px;
      padding: 0.5rem 1rem;
    }
    .usa-navbar {
      display: none;
    }
    .usa-header--basic .usa-nav {
      padding: 0px;
    }
    .usa-header--basic .usa-nav__primary {
      width: 100%;
    }
  }
</style>
